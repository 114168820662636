import { combineReducers } from 'redux'
import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { requestCountReducer } from "../reducer/requestCountReducer";
import locationChangeReducer from "../reducer/locationChangeReducer";
import { userReducer } from "../reducer/userReducer";
import alertReducer from "../reducer/alertReducer";

const createRootReducer = (history) => combineReducers({
    alerts: alertReducer,
    router: createRouterReducer(history),
    requestCount: requestCountReducer,
    locationChange: locationChangeReducer,
    user: userReducer,
});

export default createRootReducer
