import { v1 } from "uuid";

function alertReducer(state = [], action) {
    switch (action.type) {

        case 'ADD_ALERT':
            return [
                {
                    message: action.message,
                    style: action.style,
                    id: v1()
                },
                ...state
            ];

        case 'REMOVE_ALERT':
            return state.filter((alert) => {
                return alert.id !== action.id
            });

        case 'REMOVE_ALL_ALERT':
            return [];

        default:
            return state;
    }
}

export default alertReducer
