import React from 'react';
import { useSelector } from 'react-redux';

export const LOGIN_URL = process.env.REACT_APP_COGNITO_URL + '/login?client_id=' + process.env.REACT_APP_CLIENT_ID + '&response_type=token&scope=email+openid+profile&redirect_uri=' + window.location.origin + '/login-callback'

const LoginRedirect = (props) => {

    let loginUri = LOGIN_URL

    const latestLocations = useSelector((state) => state.locationChange)
    const last = latestLocations.filter((l) => l.location.pathname !== "/login")

    if (last.length > 0) {
        loginUri += '&state=' + window.btoa(last[0].location.pathname + last[0].location.search);
    }

    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    window.location = loginUri;

    return <div/>
}

export default LoginRedirect

