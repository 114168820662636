import React from 'react';
import { Link } from "react-router-dom";
import { history } from '../../core';
import { useSelector } from "react-redux";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import { ChevronLeft as ChevronLeftIcon, Dashboard, ExitToApp, Menu as MenuIcon, Person } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import logo from '../../images/small-logo.png';
import { useTheme } from "@mui/system";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'none',
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        display: 'block',
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    })
);

const ListItemLink = (props) => {
    const {to} = props;
    const pathname = history.location.pathname;

    let selected = pathname.indexOf(to) === 0;
    if (props.exact) {
        selected = history.location.pathname === to;
    }

    const listItemProps = Object.assign({}, props);
    delete listItemProps.exact;

    return (
        <ListItem {...listItemProps} selected={selected}>
            {props.children}
        </ListItem>
    );
};

const Navigation = () => {
    const theme = useTheme();
    const [menuOpen, setMenuOpen] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const requestCount = useSelector((state) => state.requestCount)


    return (<React.Fragment>
            <Box sx={{display: 'flex'}}>
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpen(true)}
                            edge="start"
                            sx={{
                                marginRight: '36px',
                                ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Avatar sx={{marginRight: theme.spacing(2)}} src={logo} alt="logo" variant='square'/>
                        <Typography variant="h6" noWrap component="div">
                            VeCoDesk: Admin
                        </Typography>
                        <Box flexGrow={1}/>
                        <IconButton
                            onClick={(e) => setMenuOpen(e.currentTarget)}
                            color="inherit"
                        >
                            <Avatar/>
                        </IconButton>
                    </Toolbar>
                    {Boolean(requestCount.count) && <LinearProgress sx={{height: '3px'}} color="secondary"/>}
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={() => setOpen(false)}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </DrawerHeader>
                    <List>
                        <ListItemLink button component={Link} key='dashboard' to={'/dashboard'} exact>
                            <ListItemIcon><Dashboard/></ListItemIcon>
                            <ListItemText primary='Dashboard'/>
                        </ListItemLink>
                        <ListItemLink button component={Link} key='systems' to={'/systems'}>
                            <ListItemIcon><ListIcon/></ListItemIcon>
                            <ListItemText primary='Systems'/>
                        </ListItemLink>
                    </List>
                    <Divider/>
                </Drawer>
            </Box>
            <Menu PaperProps={{style: {width: 250}}} open={Boolean(menuOpen)} anchorEl={menuOpen}
                  onClose={() => setMenuOpen(null)}>
                <MenuItem component={Link} to='/profile' onClick={() => setMenuOpen(null)}>
                    <ListItemIcon>
                        <Person fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                </MenuItem>
                <MenuItem component={Link} to='/login'>
                    <ListItemIcon>
                        <ExitToApp fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export default Navigation
